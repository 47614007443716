import React from "react";
import { CustomCodes } from "types";

interface HeadCustomCodeProps {
  customCodes: CustomCodes;
}
function HeadCustomCode({ customCodes }: HeadCustomCodeProps) {
  const isCodeAddedRef = React.useRef(false);
  React.useEffect(() => {
    if (customCodes?.headCode && !isCodeAddedRef.current) {
      const parsedHTML = document
        .createRange()
        .createContextualFragment(customCodes.headCode);
      document.head.appendChild(parsedHTML);
      isCodeAddedRef.current = true;
    }
  }, [customCodes?.headCode]);

  return <></>;
}

export default HeadCustomCode;
