import React from "react";
import { CustomCodes } from "types";

interface BodyCustomCode {
  customCodes: CustomCodes;
}
function BodyCustomCode({ customCodes }) {
  return <div dangerouslySetInnerHTML={{ __html: customCodes?.bodyCode }} />;
}

export default BodyCustomCode;
